@import "../../variables.scss";

.menu_section {
  position: fixed;
  width: 100%;
  z-index: 2;
}
//header
.site_header {
  background: #2a2966;
  border-bottom: 1px solid $themecolorLilaDark;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 0px;
  padding: 0.7rem calc(1.5rem - 15px);
  display: flex;
  align-items: baseline;
  @media all and (min-width: $sm-min) {
    padding: 0.7rem calc(2.25rem - 15px);
  }
  @media all and (min-width: $md-min) {
    padding: 0.7rem calc(3rem - 15px);
  }
  @media all and (min-width: $xl-min) {
    padding: 0.7rem calc(3.75rem - 15px);
  }

  .header_first_part {
    display: flex;
    align-items: center;
  }
  //main_menu
  .main_menu {
    width: 30px;
    display: inline-block;

    //menu button
    .menu_btn {
      z-index: 3;
      position: relative;
    }
    .veggie_burger {
      display: block;
      background: transparent;
      font-size: 1.2em;
      border: none;
      margin-top: 10px;
      &:hover {
        opacity: 0.7;
      }
      span {
        display: block;
        width: 30px;
        height: 3px;
        border-radius: 8px;
        background: #fff;
        margin-bottom: 7px;
        transition: all ease-in 0.3s; //obs!!
        &:last-child {
          margin-bottom: 1px;
        }
      }
      &.veggie_burger_isOpen {
        span:nth-child(1) {
          transform: rotate(45deg) translateY(14px);
        }
        span:nth-child(2) {
          opacity: 0;
          width: 0;
        }
        span:nth-child(3) {
          transform: rotate(-45deg) translateY(-14px);
        }
        + .menu_label {
          transform: translateX(-8px);
        }
      }
    }
    .menu_label {
      font-size: 0.7rem;
      margin-bottom: 0;
      padding: 1px 6px;
      transition: all ease-in 0.3s; //obs!!
    }
    //navbar
    .navbar {
      padding: 1.8rem calc(1.5rem - 15px);
      padding-top: 5rem !important;
      z-index: 2;
      @media all and (min-width: $sm-min) {
        padding: 1.8rem calc(2.25rem - 15px);
      }
      @media all and (min-width: $md-min) {
        padding: 1.8rem calc(3rem - 15px);
      }
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: fixed;
      width: 350px; //obs!
      top: 0;
      left: -350px; //obs!
      visibility: hidden;
      transition: left ease-in 0.3s; //obs!
      height: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: $themecolorLilaDarkest;
      ul.links {
        display: block;
        margin-top: 0;
        li {
          h4 {
            font-size: 1rem;
            margin: 7px 0 3px 0;
            color: #fff;
            @media all and (min-width: $sm-min) {
              margin: 10px 0 5px 0;
            }
            @media all and (min-width: 1600px) {
              margin: 15px 0 10px 0;
            }
          }
          a.link {
            color: $navLinkColor;
            &:hover {
              color: $linkColor;
              text-decoration: underline;
            }
            &.active,
            :visited {
              color: $linkColor;
            }
            &.active {
              text-decoration: underline;
            }
          }
          ul.sub_list {
            li {
              font-size: 0.9rem;
              margin: 1px 14px;
            }
          }
        }
      }
      &.navbar_is_open {
        left: 0;
        visibility: visible;
      }
    }
  }
  //thelab_logo
  .thelab_logo {
    margin-left: 1.5rem;
    font-size: 2.3rem;
    color: $themecolorNewPink;
    font-weight: 600;
  }

  //boozang logo
  .header_second_part {
    justify-content: flex-end;
    align-items: center;
    display: none;
    @media all and (min-width: $sm-min) {
      display: flex;
    }
    span {
      padding-right: 1rem;
      color: $navLinkColor;
    }
    a.boozang_link {
      //not underlining <a>
      &::after {
        opacity: 0;
      }
      &:hover {
        &::before {
          opacity: 0;
        }
      }
    }
    img {
      max-width: 130px;
    }
  }
}

//backdrop
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
//footer
.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $themecolorLilaDark;
  width: 100%;
  min-height: 20rem;
  padding: 2rem calc(1.5rem - 15px) 1rem calc(1.5rem - 15px);
  @media all and (min-width: $sm-min) {
    padding: 3rem calc(2.25rem - 15px) 1rem calc(2.25rem - 15px);
  }
  @media all and (min-width: $md-min) {
    padding: 3rem calc(3rem - 15px) 1rem calc(3rem - 15px);
  }
  @media all and (min-width: $xl-min) {
    padding: 3rem calc(3.75rem - 15px) 1rem calc(3.75rem - 15px);
  }
  .udemy_link {
    display: inline-block;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    @media all and (min-width: $md-min) {
      font-size: 1.1rem;
    }
    &:hover {
      color: $linkColor;
    }
    i {
      margin-left: 0.5rem;
    }
    img {
      max-width: 25px;
      margin-right: 3px;
      @media all and (min-width: $md-min) {
        max-width: 30px;
      }
    }
  }
  .copy {
    font-size: 0.8rem;
  }
  ul.social_icons_list {
    display: flex;
    padding: 2rem 0;
    @media all and (min-width: $md-min) {
      justify-content: flex-end;
      padding: 0;
    }
    li.social {
      a {
        font-size: 0;
        display: inline-block;
        margin-right: 2rem;
        i {
          font-size: 1.6rem;
          color: #fff;
          cursor: pointer;
          transition: color 0.3s ease-in;
        }
        //not underlining the whole <a>
        &:hover {
          i {
            color: $linkColor;
          }
          &::before {
            opacity: 0;
          }
        }
        &::after {
          opacity: 0;
        }
      }
    }
  }
}
