@import "../../variables.scss";
.result_wrapper {
  margin-top: 2rem;
  opacity: 0;
  transition: 0.5s all ease-in;
  &.show {
    opacity: 1;
  }
  .success_message {
    color: $themecolorGreen;
    font-size: 1.4rem;
    font-weight: 500;
    @media all and (min-width: $lg-min) {
      font-size: 1.5rem;
    }

    &.fail {
      color: $themecolorNewRed;
      font-weight: 500;
    }
  }
  .sub_message {
    margin-top: 0.5rem;
  }
}
