@import "../../variables.scss";

.form_section {
  form {
    margin-bottom: 2rem;
    .btn_section {
      justify-content: space-between;
      align-items: center;
      .save_message {
        color: $themecolorGreen;
        margin-bottom: 0;
        font-size: 0.9rem;
        opacity: 0;
        font-weight: 500;
        transition: 0.5s all ease-in;
        &.show {
          opacity: 1;
        }
      }
    }
  }
  .print_form {
    border-radius: $themeBorderRadius;
    box-shadow: $themeBoxShadow;
    padding: 0.2rem 0.65rem;
    margin-top: 1rem;
    opacity: 0;
    transition: 0.5s all ease-in;
    &.show {
      opacity: 1;
    }
    .table {
      overflow-x: scroll;

      thead {
        th {
          border: none;
          font-size: 0.8rem;
          padding: 0.6rem 0.75rem;
        }
      }
      tbody {
        td {
          margin-bottom: 0;
          font-size: 0.8rem;
          margin-right: 1rem;
          border-color: #3c3a92;
          padding: 0.6rem 0.75rem;
          vertical-align: baseline;
        }
        //double css???
        .icon_btn {
          color: $themecolorNewRed !important;
          transition: 0.5s all ease;
          padding: 5px 5px 10px 5px;
          font-size: 1.2rem;
          font-weight: 600;
          &:hover {
            filter: brightness(70%);
            background-image: none !important;
          }
        }
      }
    }
  }
}
