@import "../../variables.scss";

.strings_section {
  .output_section {
    opacity: 0;
    transition: 0.5s all ease-in;
    &.show {
      opacity: 1;
    }
    .strings {
      padding: 1rem 0;
      p {
        margin: 0;
      }
    }
  }
}
