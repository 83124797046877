@import "../../variables.scss";

.mobile_game_section {
  display: block;
  @media all and (min-width: $sm-min) {
    display: none;
  }
}
.game_section {
  .game_header {
    width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    .game_header_counter {
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: 0.9rem;
      &.time {
        strong {
          color: $themecolorNewPink;
        }
      }
      &.points {
        strong {
          color: $themecolorGreen;
        }
      }
      strong {
        font-size: 1.4rem;
      }
    }
  }
  //kitten collect
  .playground {
    position: relative;
    background: $themecolorLilaDark;
    box-shadow: $themeBoxShadow;
    border-radius: $themeBorderRadius;
    margin: 0 0 1.5rem 0;
    width: 450px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    .message {
      position: relative;
      z-index: 500;
      max-width: 50%;
      text-align: center;
      p {
        line-height: 1.5;
        margin-bottom: 0;
        font-size: 1.8rem;
        font-weight: 500;
        color: $themecolorBlueNewLightest;
      }
    }
    .kitten,
    .hedgehog {
      cursor: pointer;
      position: absolute;
      padding: 8px;
      margin-bottom: 0;
      img {
        max-width: 35px;
      }
    }
  }
  //canvas
  .canvas {
    background: $themecolorLilaDark;
    box-shadow: $themeBoxShadow;
    border-radius: $themeBorderRadius;
  }
  display: none;
  @media all and (min-width: $sm-min) {
    display: block;
  }
}
